var arrayContent = [];

var counter = 0;
var isSlideRunning = false;

var inspirations = document.querySelector('.inspirations');
var inspirationsContent = document.querySelector('.inspirations .inspirations__content');
var inspirationsUl = document.querySelector('.inspirations__content .main ul');
var inspirationsLi = document.querySelectorAll('.inspirations__content .main li');
var inspirationsLiLink = document.querySelectorAll('.inspirations__content li a');
var wrapper = document.querySelector('.inspirations .wrapper');

var arrowsInspirations = document.querySelectorAll('.inspirations .arrow');

var width;

var counterInspirations = 0;
var counterFull = 0;
var oldCounter;
var oldCounterInspirations = 0;
var translateCounter = 0;

var isTranslateRuning = false;

for (var i = 0; i < arrowsInspirations.length; i++) {
    arrowsInspirations[i].addEventListener('click', function(e) {
        if (isTranslateRuning === false) {
            oldCounterInspirations = counterInspirations;
            oldCounter = counterFull;
            isTranslateRuning = true;
            if (this === arrowsInspirations[0]) {
                left();
                counterInspirations--;
                counterFull--;
                if (counterInspirations < 0) {
                    counterInspirations = inspirationsLi.length - 1;
                }
            } else if (this === arrowsInspirations[1]) {
                counterInspirations++;
                counterFull++;
                if (counterInspirations > inspirationsLi.length - 1) {
                    counterInspirations = 0;
                }
                inspirationsLi[oldCounterInspirations].classList.add('opacity');
            }
            translateInspirations();
        }
    });
}

// Translate UL
function translateInspirations() {
    for (var i = 0; i < inspirationsLi.length; i++) {
        translate = (counterFull * -width);
        inspirationsUl.style.transform = "translateX(" + translate + "px)";
    }
}


function setValue() {
    width = inspirationsLi[0].offsetWidth + Number((window.getComputedStyle(inspirationsLi[0], null).getPropertyValue("margin-left")).slice(0, 2));
    height = inspirationsLi[0].offsetHeight;

    // Set value for Ul & Wrapper
    inspirationsUl.style.width = width * (inspirationsLi.length) + "px";
    wrapper.style.width = width * (inspirationsLi.length) + "px";
    inspirationsUl.style.height = height + "px";

    // Set position of Li
    for (var i = 0; i < inspirationsLi.length; i++) {
        inspirationsLi[i].style.left = width * (i) + "px";
    }
}
setValue();

// Position for the right translate
inspirationsUl.addEventListener('transitionend', function(e) {
    if (e.propertyName === "transform" && e.elapsedTime != 0.3) {
        isTranslateRuning = false;
        if (oldCounter < counterFull) {
            oldLeft = Number((inspirationsLi[oldCounterInspirations].style.left).slice(0, -2));
            inspirationsLi[oldCounterInspirations].style.left = (oldLeft + width * (inspirationsLi.length)) + "px";
        }

        // Remove the opacity class at the end of translate
        for (var i = 0; i < inspirationsLi.length; i++) {
            inspirationsLi[i].classList.remove('opacity');
        }
    }
});

// Position for the left translate
function left() {
    counterLeft = counterInspirations - 1;
    if (counterLeft < 0) {
        counterLeft = inspirationsLi.length - 1;
    }
    oldLeft = Number((inspirationsLi[counterLeft].style.left).slice(0, -2));
    inspirationsLi[counterLeft].style.left = (oldLeft - width * (inspirationsLi.length)) + "px";
}

// --------------------------------------------------------------------------

var jsReplaceImg = document.querySelectorAll('.inspirations__content .js-replace-img');

window.addEventListener('resize', function(e) {
    if (window.innerWidth <= 768) {
        wrapper.style.width = "";
    }
});

var caseUl = document.querySelector('.cases-studies .main ul');
var caseLi = document.querySelectorAll('.cases-studies .main ul li');
var caseImg = document.querySelectorAll('.cases-studies .main ul li img');
var caseLinks = document.querySelectorAll('.cases-studies .main ul li a');
var caseP = document.querySelectorAll('.cases-studies .main ul li p');
var caseCircle = document.querySelectorAll('.cases-studies footer ul li');
var flagCase = false;
var caseCircleArray = [];
for (var i = 0; i < caseCircle.length; i++) {
    caseCircleArray.push(caseCircle[i]);
}
var caseArray = [];
for (var i = 0; i < caseLi.length; i++) {
    caseArray.push({
        img: caseImg[i].getAttribute('src'),
        p: caseP[i].innerHTML,
        link: caseLinks[i].getAttribute('href')
    });
}
for (var i = 0; i < caseCircle.length; i++) {
    caseCircle[i].addEventListener('click', function(e) {
        if (this.classList.contains('active') === false && flagCase === false) {
            flagCase = true;
            target = caseCircleArray.indexOf(this);

            caseImg[0].classList.add('transition');
            caseP[0].classList.add('transition');

            for (var i = 0; i < caseCircle.length; i++) {
                caseCircle[i].classList.remove('active');
                caseCircle[target].classList.add('active');
            }
        }
    });
}

if (window.screen.width === 1366 && (window.screen.height) === 768) {
    document.querySelector('.items .content').style.transform = "translate(0)";
    document.querySelector('.items .content').style.margin = "50px 0px";
}
